import React from "react"
import ImageLoader from "./imageLoader"

const TeamCard = ({ member }) => {
  return (
    <div
      style={{ objectFit: "cover" }}
      className="object-cover relative border-black border-2 "
    >
      {/* //w-auto h-64 sm:h-80 md:h-96  */}
      {/* <div className="absolute z-50 h-full w-full bg-createbeige hover:bg-createneongreen hover:opacity-70 opacity-50"></div>
  <div className="absolute z-50 p-6 w-full opacity-100 pointer-events-none uppercase text-xl sm:text-2xl 2xl:text-3xl bottom-0">
    {client.title}
  </div> */}
      <ImageLoader
        image={member.teamMember.image}
        // className="border-black border-r-2"
        objectFit="cover"
        imgStyle={{ objectFit: "cover" }}
        className="object-cover h-full"
      />
      <div className="px-2 pt-3 pb-1 border-black border-t-2 border-b-2">
        <div className="mr-4 inline-block uppercase align-middle">Name:</div>
        <div className="font-antroVectra inline-block font-bold text-md tracking-tighter xl:tracking-[0] text-lg xl:text-3xl align-top">
          {member.title}
        </div>
      </div>
      <div className="px-2 pt-3 pb-1 border-black border-b-2">
        <div className="mr-4 inline-block uppercase align-middle">Role:</div>
        <div className="font-antroVectra inline-block font-bold text-md tracking-tighter xl:tracking-[0] text-lg xl:text-3xl align-top">
          {member.teamMember.role}
        </div>
      </div>
      <div className="px-2 pt-3 pb-1 border-black border-b-2">
        <div className="mr-4 inline-block uppercase align-middle">
          Fave Dish:
        </div>
        <div className="font-antroVectra inline-block font-bold text-md tracking-tighter xl:tracking-[0] text-lg xl:text-3xl align-top">
          {member.teamMember.faveDish}
        </div>
      </div>
      <div className="px-2 pt-3 pb-1 border-black">
        <div className="mr-4 inline-block uppercase align-middle">
          Currently:
        </div>
        <div className="font-antroVectra inline-block font-bold text-md tracking-tighter xl:tracking-[0] text-lg xl:text-3xl align-top">
          {member.teamMember.currently}
        </div>
      </div>

      {/* <img
    src="https://picsum.photos/1000/1000"
    draggable="false"
    className="object-cover"
  /> */}
    </div>
  )
}

export default TeamCard
