import React from "react"
import TeamCard from "./teamCard"

const TeamMember = ({ reverse, member, i }) => {
  return (
    <div>
      <div className="sm:grid sm:grid-cols-2 sm:items-center lg:-mt-20 xl:-mt-44">
        <div
          className={"p-6 pb-0 lg:p-12 w-full " + (reverse ? "" : "sm:order-1")}
        >
          <h2
            className={
              "block sm:hidden uppercase mb-2 lg:mb-6 text-2xl " +
              (reverse ? "" : "text-right")
            }
          >
            Meet {member.id === "cG9zdDo3ODMz" ? "our maker" : member.title}
          </h2>
          <TeamCard member={member} />
        </div>
        <div
          className={
            "p-6 lg:p-12 " +
            (reverse ? "sm:order-1" : "text-left sm:text-right ml-auto")
          }
        >
          <h2 className={"hidden sm:block uppercase mb-2 lg:mb-6 text-3xl "}>
            Meet {member.id === "cG9zdDo3ODMz" ? "our maker" : member.title}
          </h2>
          <div className="max-w-lg leading-6 ">{member.teamMember.bio}</div>
        </div>
      </div>
    </div>
  )
}

export default TeamMember
