import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import PageLink from "../components/pageLink"
import StyledHeader from "../components/styledHeader"
import TeamMember from "../components/teamMember"

import Seo from "gatsby-plugin-wpgraphql-seo"

const AboutUs = () => {
  const about = useStaticQuery(graphql`
    query {
      wpPage(id: { eq: "cG9zdDo3Nzk1" }) {
        id
        title
        nodeType
        uri
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
        aboutUs {
          column1
          column2
          header1 {
            headerText
            headerTextBackground
          }
          link1 {
            link
            text
          }
          link2 {
            link
            text
          }
          heroImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      allWpTeamMember(sort: { fields: menuOrder, order: ASC }) {
        nodes {
          id
          teamMember {
            bio
            currently
            faveDish
            fieldGroupName
            role
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              altText
            }
          }
          title
        }
      }
    }
  `)

  console.log(about)

  return (
    <Layout title="About Us" titleImg={about.wpPage.aboutUs.heroImage}>
      <Seo post={about.wpPage} />
      <div className="border-black border-b-2 pb-12">
        <div className="mx-auto max-w-screen-2xl">
          <div className="p-7">
            <StyledHeader
              backgroundText={about.wpPage.aboutUs.header1.headerTextBackground}
              Tag="h1"
            >
              {about.wpPage.aboutUs.header1.headerText}
            </StyledHeader>
          </div>
          <div className="">
            <div className="sm:grid sm:grid-cols-2 sm:p-5">
              <div className="p-5 lg:p-8 text-justify">
                {about.wpPage.aboutUs.column1}{" "}
              </div>
              <div className="p-5 lg:p-8 text-justify">
                {about.wpPage.aboutUs.column2}{" "}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:text-center">
              <div className="p-5 pb-2">
                <PageLink url={about.wpPage.aboutUs.link1.link}>
                  {about.wpPage.aboutUs.link1.text}
                </PageLink>
              </div>
              <div className="p-5">
                <PageLink url={about.wpPage.aboutUs.link2.link}>
                  {about.wpPage.aboutUs.link2.text}
                </PageLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-black pb-12">
        <div className="mx-auto max-w-screen-2xl">
          <div className="sm:grid sm:grid-cols-2 xl:mt-20">
            <div className="p-8 sm:order-2 sm:col-span-2 lg:col-span-1">
              <StyledHeader backgroundText="our team" Tag="h2">
                Our Team
              </StyledHeader>
            </div>
            <div className=""></div>
          </div>
          {about.allWpTeamMember.nodes.map((item, i) => {
            return (
              <TeamMember member={item} reverse={i % 2 === 0 ? true : false} />
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs
